import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.getErrorMessage(error);
        if (errorMessage) {
          this.notificationService.showError(errorMessage.message, errorMessage.title);
        }
        return throwError(() => error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): { title: string; message: string } | null {
    const backendMessage = error.error?.message || null;

    switch (error.status) {
      case 400:
        return {
          title: 'Bad Request',
          message: backendMessage || 'There was an issue with your request. Please check your input.',
        };
      case 401:
        return {
          title: 'Unauthorized',
          message: backendMessage || 'Your session has expired. Please log in again.',
        };
      case 403:
        return {
          title: 'Forbidden',
          message: backendMessage || `Access denied: You don't have permission to access ${error.url}`,
        };
      case 404:
        return {
          title: 'Not Found',
          message: backendMessage || 'The requested resource was not found.',
        };
      case 500:
        return {
          title: 'Server Error',
          message: backendMessage || 'Something went wrong on the server. Please try again later.',
        };
      case 0:
        return {
          title: 'Network Error',
          message: backendMessage || 'Please check your internet connection.',
        };
      default:
        return {
          title: 'Error',
          message: backendMessage || error.message || 'An unexpected error occurred.',
        };
    }
  }
}
